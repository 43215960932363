import React, { useEffect } from 'react';
import { navigate, useLocation } from '@reach/router';

const localesSupported = process.env.SUPPORTED_LOCALES.split(',');

const pageNotFound = () => {
  const location = useLocation();
  useEffect(() => {
    const lang = location.pathname.split('/')[1];
    if (localesSupported.includes(lang)) {
      navigate(`/${lang}/`);
    } else {
      navigate('/en/');
    }
  }, []);
  return <></>;
};

export default pageNotFound;
